export const products = [
  {
    id: 1,
    img: "https://www.facebook.com/photo/?fbid=4481796188520926&set=a.411583325542253",
    link: "https://www.facebook.com/aafgad",
  },
  {
    id: 2,
    img: "https://www.facebook.com/photo.php?fbid=1646515962048977&set=t.100000717686720&type=3",
    link: "https://www.facebook.com/aafgad",
  },
  {
    id: 3,
    img: "https://www.facebook.com/photo/?fbid=10157685603286572&set=pob.513491039",
    link: "https://www.facebook.com/aafgad",
  },
  {
    id: 4,
    img: "https://www.facebook.com/photo.php?fbid=10201305347529571&set=t.100000717686720&type=3",
    link: "https://www.facebook.com/aafgad",
  },
  {
    id: 5,
    img: "https://www.facebook.com/photo.php?fbid=10155839431801572&set=t.100000717686720&type=3",
    link: "https://www.facebook.com/aafgad",
  },
  {
    id: 6,
    img: "https://www.facebook.com/photo/?fbid=2780893858813610&set=pob.100006790684839",
    link: "https://www.facebook.com/aafgad",
  },
];

// export const products = [
//   {
//     id: 1,
//     img: "https://www.google.com/search?q=udemy+image&rlz=1C1VDKB_en-GBAU962AU962&sxsrf=AOaemvKn-OhLaVvQkVp_fEmHAdhxrER4tg:1635924778145&source=lnms&tbm=isch&sa=X&ved=2ahUKEwi25J7a1vvzAhXBXCsKHVgwAnQQ_AUoAXoECAEQAw&biw=962&bih=951&dpr=1#imgrc=FxNgqQvRkpnv8M",
//     link: "udemy.com",
//   },
//   {
//     id: 2,
//     img: "https://www.pexels.com/photo/black-horse-running-on-green-field-surrounded-with-trees-101667/",
//     link: "http://pexels.com",
//   },
//   {
//     id: 3,
//     img: "https://www.pexels.com/photo/black-horse-running-on-green-field-surrounded-with-trees-101667/",
//     link: "http://pexels.com",
//   },
//   {
//     id: 4,
//     img: "https://www.pexels.com/photo/black-horse-running-on-green-field-surrounded-with-trees-101667/",
//     link: "http://pexels.com",
//   },
//   {
//     id: 5,
//     img: "https://www.pexels.com/photo/black-horse-running-on-green-field-surrounded-with-trees-101667/",
//     link: "http://pexels.com",
//   },
//   {
//     id: 6,
//     img: "https://www.pexels.com/photo/black-horse-running-on-green-field-surrounded-with-trees-101667/",
//     link: "http://pexels.com",
//   },
// ];
