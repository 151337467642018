import "./contact.css";
import phone from "../img/phone.jpg";
import Email from "../img/email.jpg";
import Address from "../img/download.jpg";
import { useContext, useRef, useState } from "react";
import emailjs from "emailjs-com";
import { ThemeContext } from "../context";
export const Contact = () => {
  const formRef = useRef();

  const [done, setDone] = useState(false);
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "anildai4@gmail.com",
        "template_3o6f90y",
        formRef.current,
        "user_z9ZsCC88L7gmMZLmPqZyd"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="c">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Let's discuss about your project</h1>
          <div className="c-info">
            <div className="c-info-item">
              <img src={phone} alt="" className="c-icon" />
              +61424929841
            </div>
            <div className="c-info-item">
              <img src={Email} alt="" className="c-icon" />
              anildai4@gmail.com
            </div>
            <div className="c-info-item">
              <img src={Address} alt="" className="c-icon" />
              Hurstville,NSW,Australia
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <b>Hey,What's up??</b> Get in touch. If you want to make website let
            me know..Thank you
          </p>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              style={{ backgroundColor: darkMode && "#333" }}
              type="text"
              placeholder="Name"
              name="user_name"
            />
            <input
              style={{ backgroundColor: darkMode && "#333" }}
              type="text"
              placeholder="Subject"
              name="user_subject"
            />
            <input
              style={{ backgroundColor: darkMode && "#333" }}
              type="text"
              placeholder="Email"
              name="user_email"
            />
            <textarea
              style={{ backgroundColor: darkMode && "#333" }}
              rows="5"
              placeholder="Message"
              name="message"
            />
            <button>Submit</button>
            {done && "Thank you..."}
          </form>
        </div>
      </div>
    </div>
  );
};

// import "./contact.css";
// import Phone from "../img/phone.jpg";
// import Email from "../img/email.jpg";
// import Address from "../img/download.jpg";
// import { useContext, useRef, useState } from "react";
// import emailjs from "emailjs-com";
// import { ThemeContext } from "../context";

// const Contact = () => {
//   const formRef = useRef();
//   const [done, setDone] = useState(false);
//   const theme = useContext(ThemeContext);
//   const darkMode = theme.state.darkMode;

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         "anildai4@gmail.com",
//         "template_3o6f90y",
//         formRef.current,
//         "user_z9ZsCC88L7gmMZLmPqZyd"
//       )
//       .then(
//         (result) => {
//           console.log(result.text);
//           setDone(true);
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };

//   return (
//     <div className="c">
//       <div className="c-bg"></div>
//       <div className="c-wrapper">
//         <div className="c-left">
//           <h1 className="c-title">Let's discuss your project</h1>
//           <div className="c-info">
//             <div className="c-info-item">
//               <img src={Phone} alt="" className="c-icon" />
//               +1 2 556 75
//             </div>
//             <div className="c-info-item">
//               <img className="c-icon" src={Email} alt="" />
//               anildai4@gmail.com
//             </div>
//             <div className="c-info-item">
//               <img className="c-icon" src={Address} alt="" />
//               245 King Street, Touterie Victoria 8520 Australia
//             </div>
//           </div>
//         </div>
//         <div className="c-right">
//           <p className="c-desc">
//             <b>What’s your story?</b> Get in touch. Always available for
//             freelancing if the right project comes along. me.
//           </p>
//           <form ref={formRef} onSubmit={handleSubmit}>
//             <input
//               style={{ backgroundColor: darkMode && "#333" }}
//               type="text"
//               placeholder="Name"
//               name="user_name"
//             />
//             <input
//               style={{ backgroundColor: darkMode && "#333" }}
//               type="text"
//               placeholder="Subject"
//               name="user_subject"
//             />
//             <input
//               style={{ backgroundColor: darkMode && "#333" }}
//               type="text"
//               placeholder="Email"
//               name="user_email"
//             />
//             <textarea
//               style={{ backgroundColor: darkMode && "#333" }}
//               rows="5"
//               placeholder="Message"
//               name="message"
//             />
//             <button>Submit</button>
//             {done && "Thank you..."}
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Contact;
