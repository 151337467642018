import "./product.css";
// function Product = ({ img,link }) => {
//   return (
//     <div className="p">
//       <div className="p-browser">
//         <p className="p-circle"></p>
//         <p className="p-circle"></p>
//         <p className="p-circle"></p>
//       </div>
//       <a href={link} target="_blank" rel="noreferrer">
//         <img src={img} alt="" className="p-img" />
//       </a>
//     </div>
//   );
// };

// export default Product;

const Product = ({ img, link }) => {
  return (
    <div className="p">
      <div className="p-browser">
        <div className="p-circle"></div>
        <div className="p-circle"></div>
        <div className="p-circle"></div>
      </div>
      <a href={img} target="_blank">
        <img src={link} alt={link} className="p-img" />
      </a>
    </div>
  );
};
export default Product;
